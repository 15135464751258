<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="车辆编号" prop="vehicleId">
        <a-input v-model="form.vehicleId" placeholder="请输入车辆编号" />
      </a-form-model-item>
      <a-form-model-item label="车辆名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入车辆名称" />
      </a-form-model-item>
      <a-form-model-item label="车牌号" prop="licensePlateNum">
        <a-input v-model="form.licensePlateNum" placeholder="请输入车牌号" />
      </a-form-model-item>
      <a-form-model-item label="车辆内部ID" prop="vehicleInnerId">
        <a-input v-model="form.vehicleInnerId" placeholder="请输入车辆内部ID" />
      </a-form-model-item>
      <a-form-model-item label="操作员" prop="operatorId">
        <a-select v-model="form.operatorId" placeholder="请选择">
          <a-select-option v-for="(d, index) in operatorOptions" :key="index" :value="d.userId">
            {{ d.userName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所属机构" prop="deptId">
        <a-tree-select
          v-model="form.deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="车辆类别" prop="vehicleCategory">
        <a-select placeholder="请选择车辆类别" v-model="form.vehicleCategory">
          <a-select-option v-for="(d, index) in vehicleCategoryOptions" :key="index" :value="Number(d.dictValue)">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="车架编号">
        <a-input v-model="form.frameNo" placeholder="请输入车架编号" />
      </a-form-model-item>
      <a-form-model-item label="车辆状态:" prop="enabled">
        <a-radio-group v-model="form.enabled" button-style="solid">
          <a-radio-button v-for="(d, index) in vehicleStatusOptions" :key="index" :value="Number(d.dictValue)">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否自动驾驶:" prop="automode">
        <a-radio-group v-model="form.automode" button-style="solid">
          <a-radio-button v-for="(d, index) in automodeOptions" :key="index" :value="Number(d.dictValue)">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <!-- <a-form-model-item label="mqtt发送指令" prop="result">
        <a-input v-model="form.result" placeholder="请输入数字" />注：1:Aliyun，2:MyServer
      </a-form-model-item> -->
      <a-form-model-item label="车端MAC地址（与车端通信）" prop="mqttTopic">
        <a-input v-model="form.mqttTopic" placeholder="请输入mqttTopic" />注：为空则使用vehicleId
      </a-form-model-item>
      <a-form-model-item label="是否虚拟车" prop="virtualVehicle">
        <a-radio-group v-model="form.virtualVehicle" button-style="solid">
          <a-radio-button v-for="(d, index) in virtualeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="form.virtualVehicle === 1" label="默认经度(lng)" prop="lng">
        <a-input v-model="form.lng" placeholder="请输入默认经度" />
      </a-form-model-item>
      <a-form-model-item v-if="form.virtualVehicle === 1" label="默认纬度(lat)" prop="lat">
        <a-input v-model="form.lat" placeholder="请输入默认纬度" />
      </a-form-model-item>
      <a-form-model-item v-if="form.virtualVehicle === 1" label="默认转向" prop="virtualHeading">
        <a-input v-model="form.virtualHeading" placeholder="请输入默认转向" />
      </a-form-model-item>
      <a-form-model-item v-if="form.virtualVehicle === 1" label="随机里程" prop="odoVehicle">
        <a-input v-model="form.odoVehicle" placeholder="请输入随机里程" />
      </a-form-model-item>
      <a-form-model-item v-if="form.virtualVehicle === 1" label="绑定真实车的车辆编号" prop="bindVehicleId">
        <a-input v-model="form.bindVehicleId" placeholder="请输入绑定真实车的车辆编号" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVehicle, addVehicle, updateVehicle } from '@/api/system/vehicle'
import { TreeSelect } from 'ant-design-vue'

export default {
  name: 'CreateForm',
  props: {
    automodeOptions: {
      type: Array,
      required: true
    },
    vehicleCategoryOptions: {
      type: Array,
      required: true
    },
    vehicleStatusOptions: {
      type: Array,
      required: true
    },
    // 所属机构选项
    deptOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    ATreeSelect: TreeSelect
  },
  data() {
    return {
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      loading: false,
      // 操作员选项
      operatorOptions: [],
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        vehicleId: null,
        name: null,
        licensePlateNum: null,
        operatorId: null,
        vehicleInnerId: null,
        vehicleCategory: null,
        password: null,
        deptId: null,
        mqttTopic: null,
        automode: 1,
        virtualVehicle: null,
        lng: null,
        lat: null,
        bindVehicleId: null,
        virtualHeading: null,
        odoVehicle: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        vehicleId: [{ required: true, message: '车辆编号不能为空', trigger: 'blur' }],
        virtualVehicle: [{ required: true, message: '请选择是否虚拟车辆', trigger: 'change' }]
      },
      virtualeOptions: [
        { dictValue: 1, dictLabel: '是' },
        { dictValue: 0, dictLabel: '否' }
      ]
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {
    'form.virtualVehicle'(val) {
      console.log('virtualVehicle', val)
      if (val === 1) {
        this.rules = {
          vehicleId: [{ required: true, message: '车辆编号不能为空', trigger: 'blur' }],
          virtualVehicle: [{ required: true, message: '请选择是否虚拟车辆', trigger: 'blur' }],
          lng: [{ required: true, message: '经度不能为空', trigger: 'blur' }],
          lat: [{ required: true, message: '维度不能为空', trigger: 'blur' }],
          bindVehicleId: [{ required: true, message: '绑定真实车辆编号不能为空', trigger: 'blur' }]
        }
      } else {
        this.rules = {
          vehicleId: [{ required: true, message: '车辆编号不能为空', trigger: 'blur' }],
          virtualVehicle: [{ required: true, message: '请选择是否虚拟车辆', trigger: 'blur' }]
        }
      }
    }
  },
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        vehicleId: null,
        name: null,
        licensePlateNum: null,
        operatorId: null,
        vehicleInnerId: null,
        vehicleCategory: null,
        password: null,
        deptId: null,
        mqttTopic: null,
        automode: 1,
        virtualVehicle: null,
        lng: null,
        lat: null,
        bindVehicleId: null,
        virtualHeading: null,
        odoVehicle: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      getVehicle(0).then((response) => {
        this.operatorOptions = response.operators
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVehicle(id).then((response) => {
        this.form = response.data
        this.form.password = ''
        this.operatorOptions = response.operators
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const form = JSON.parse(JSON.stringify(this.form))
          if (form.virtualVehicle !== 1) {
            delete form.lng
            delete form.lat
            delete form.bindVehicleId
            delete form.odoVehicle
            delete form.virtualHeading
          }
          if (form.id !== undefined && form.id !== null) {
            updateVehicle(form).then((response) => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addVehicle(form).then((response) => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
