import request from '@/utils/request'

// 查询车辆管理列表
export function listVehicle(query) {
  return request({
    url: '/iot/vehicle/list',
    method: 'get',
    params: query
  })
}

// 查询车队列表
export function vlistVehicle(query) {
  return request({
    url: '/iot/vehicle/vlist',
    method: 'get',
    params: query
  })
}

// 我的车辆
export function getOpVehicles(query) {
  return request({
    url: '/iot/vehicle/getOpVehicles',
    method: 'get',
    params: query
  })
}

// 查询车辆管理详细
export function getVehicle(id) {
  return request({
    url: '/iot/vehicle/' + id,
    method: 'get'
  })
}

// 新增车辆管理
export function addVehicle(data) {
  return request({
    url: '/iot/vehicle',
    method: 'post',
    data: data
  })
}

// 修改车辆管理
export function updateVehicle(data) {
  return request({
    url: '/iot/vehicle',
    method: 'put',
    data: data
  })
}

// 删除车辆管理
export function delVehicle(id) {
  return request({
    url: '/iot/vehicle/' + id,
    method: 'delete'
  })
}

// 导出车辆管理
export function exportVehicle(query) {
  return request({
    url: '/iot/vehicle/export',
    method: 'get',
    params: query
  })
}

// 检测车端通讯
export function checkRegMac(id) {
  return request({
    url: '/iot/vehicle/regMac/' + id,
    method: 'post'
  })
}
