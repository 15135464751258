var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆编号", prop: "vehicleId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车辆编号" },
                model: {
                  value: _vm.form.vehicleId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleId", $$v)
                  },
                  expression: "form.vehicleId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆名称", prop: "name" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车辆名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车牌号", prop: "licensePlateNum" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车牌号" },
                model: {
                  value: _vm.form.licensePlateNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "licensePlateNum", $$v)
                  },
                  expression: "form.licensePlateNum",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆内部ID", prop: "vehicleInnerId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车辆内部ID" },
                model: {
                  value: _vm.form.vehicleInnerId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleInnerId", $$v)
                  },
                  expression: "form.vehicleInnerId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "操作员", prop: "operatorId" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.operatorId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "operatorId", $$v)
                    },
                    expression: "form.operatorId",
                  },
                },
                _vm._l(_vm.operatorOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.userId } },
                    [_vm._v(" " + _vm._s(d.userName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属机构", prop: "deptId" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.deptOptions,
                  placeholder: "请选择",
                  replaceFields: _vm.replaceFields,
                  "tree-default-expand-all": "",
                },
                model: {
                  value: _vm.form.deptId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deptId", $$v)
                  },
                  expression: "form.deptId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆类别", prop: "vehicleCategory" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择车辆类别" },
                  model: {
                    value: _vm.form.vehicleCategory,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vehicleCategory", $$v)
                    },
                    expression: "form.vehicleCategory",
                  },
                },
                _vm._l(_vm.vehicleCategoryOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: Number(d.dictValue) } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车架编号" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车架编号" },
                model: {
                  value: _vm.form.frameNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "frameNo", $$v)
                  },
                  expression: "form.frameNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆状态:", prop: "enabled" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.enabled,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "enabled", $$v)
                    },
                    expression: "form.enabled",
                  },
                },
                _vm._l(_vm.vehicleStatusOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: Number(d.dictValue) } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否自动驾驶:", prop: "automode" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.automode,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "automode", $$v)
                    },
                    expression: "form.automode",
                  },
                },
                _vm._l(_vm.automodeOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: Number(d.dictValue) } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "车端MAC地址（与车端通信）", prop: "mqttTopic" },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入mqttTopic" },
                model: {
                  value: _vm.form.mqttTopic,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mqttTopic", $$v)
                  },
                  expression: "form.mqttTopic",
                },
              }),
              _vm._v("注：为空则使用vehicleId "),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否虚拟车", prop: "virtualVehicle" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.virtualVehicle,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "virtualVehicle", $$v)
                    },
                    expression: "form.virtualVehicle",
                  },
                },
                _vm._l(_vm.virtualeOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.virtualVehicle === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "默认经度(lng)", prop: "lng" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入默认经度" },
                    model: {
                      value: _vm.form.lng,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "lng", $$v)
                      },
                      expression: "form.lng",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.virtualVehicle === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "默认纬度(lat)", prop: "lat" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入默认纬度" },
                    model: {
                      value: _vm.form.lat,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "lat", $$v)
                      },
                      expression: "form.lat",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.virtualVehicle === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "默认转向", prop: "virtualHeading" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入默认转向" },
                    model: {
                      value: _vm.form.virtualHeading,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "virtualHeading", $$v)
                      },
                      expression: "form.virtualHeading",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.virtualVehicle === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "随机里程", prop: "odoVehicle" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入随机里程" },
                    model: {
                      value: _vm.form.odoVehicle,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "odoVehicle", $$v)
                      },
                      expression: "form.odoVehicle",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.virtualVehicle === 1
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "绑定真实车的车辆编号",
                    prop: "bindVehicleId",
                  },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入绑定真实车的车辆编号" },
                    model: {
                      value: _vm.form.bindVehicleId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bindVehicleId", $$v)
                      },
                      expression: "form.bindVehicleId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }